<template>
  <div class="home">
    <h2 class="title text-center">网培师网站QA常见问题</h2>
    <h3>一、账号问题</h3>
    <p>Q：个人资料完善后还可以更改吗？</p>
    <p>
      A：在首次完善资料时，考生的姓名及身份证号需如实填写，个人信息的真实性会影响考试流程的正常进展及证书的生成和邮寄，其他的信息后续如有变更可以直接修改；
    </p>
    <br />
    <p>Q：想更改手机号或账号密码在哪里更改？</p>
    <p>A：请前往个人中心-个人资料里按流程填写即可。</p>
    <br />
    <p>Q：如果我的信息因特殊原因需要更改怎么办？</p>
    <p>A：请考生联系我们网培师平台的客服。</p>
    <br />
    <br />
    <h3>二、考试问题</h3>
    <p>Q：报考有条件限制吗？</p>
    <p>
      A：网培师平台目前报考需要考生必须成年且拥有中专以上的学历，满足条件的考生，只需按照流程缴费完善资料参加考试即可；
    </p>
    <br />
    <p>Q：考生如何参加考试？</p>
    <p>
      A：考生只需在正式考试开放时间内选择考试模块进入考试即可，在考试前和考试中平台将通过摄像头进行验证身份及监考，请考生选择有摄像头设备的电脑进行考试；
    </p>
    <br />
    <p>Q：参加考试时有身份验证吗？</p>
    <p>
      A：在正式考试前，我们将通过身份验证系统及摄像头扫描面部识别认证，以此来核实参加考试的是否为报考本人，一但核实不通过，平台将不允许该考生进行考试！
    </p>
    <br />
    <p>Q：网培师考试是开放性的吗？</p>
    <p>
      A：网培师的每个模块考试都需考生独自完成，考生在答题过程中不得以任何方式向第三方求助，且考试中途不可离场，不可有其他无关人员进入摄像头有效区域，否则将视为作弊处理，一但发现考生有作弊行为，平台将永久取消考生考试资格，并不予退还已交费用！
    </p>
    <br />
    <p>Q：报名后需要考几个模块？</p>
    <p>
      A：想考取初级网培师资格证书的考生需通过“教学综合能力评测”和“网培政策规范考试”两个模块并达到及格线即可获得初级网培师证书。想考取中级网培师资格证书的考生需通过所有考试并达到及格线即可获得中级网培师资格证书；
    </p>
    <br />
    <p>Q：需要同一天考完所有模块吗？</p>
    <p>A：考生需要在一天内参加三个科目并完成考试。</p>
    <br />
    <p>Q：考试不通过怎么办？</p>
    <p>
      A：在成功付费后，考生在每个模块都可获得一次正式考试和一次补考的机会，正式考试不过考生可使用补考机会再次考试，若补考也未通过，则需要考生重新缴纳全额费用全部重考；
    </p>
    <br />
    <p>Q：补考规则是什么？</p>
    <p>
      A：在第一次考试结果下来后，学员需要在一个月内完成其他所有不及格科目的补考，如果学员无法在一个月内完成补考，则扣除该学员的补考机会！补考时只需要补考未通过的科目即可。
    </p>
    <br />
    <br />
    <h3>三、证书问题</h3>
    <p>Q：网培师证书是什么?</p>
    <p>
      A：网培师证书是在线教育行业从业或兼职教学人员的许可证，覆盖行业范围广泛且赋有权威性！
    </p>
    <br />
    <p>Q：网培师证书国内是否承认？</p>
    <p>A：网培师平台目前已经取得多家权威机构合作，详情请查看证书介绍板块！</p>
    <br />
    <p>Q：网培师证书时效性有多久？</p>
    <p>A：证书时效性目前是永久有效！</p>
    <br />
    <p>Q：一次缴费可以考取几本证书？</p>
    <p>
      A：每次缴费只可获取一本证书，在缴费参加第一次评测后，如若学员成功申请了初级证书，再想申请其他证书的情况下则需要重新缴费评测，如果学员成功申请了中级证书后，则不可再申请初级证书。
    </p>
    <br />
    <p>Q：我可以在第一次考试不申请证书吗？</p>
    <p>
      A：在考试结果出来后，如若成绩只能满足申请初级证书，学员可不申请初级证书，然后通过补考满足中级证书条件再申请中级证书。没有补考机会则只能申请初级证书。
    </p>
    <br />
    <br />
    <h3>四、缴费问题</h3>
    <p>Q：怎么进行缴费？</p>
    <p>
      A：进入评测模块参加模拟练习或正式考试即可进行缴费，缴费可选择使用兑换券或线上缴费，选择线上缴费时可直接通过微信或支付宝扫码付款；
    </p>
    <br />
    <p>Q：付费后可退款吗？</p>
    <p>A：考生付费后平台将不提供退款服务；</p>
    <br />
    <p>Q：缴费之后获得的权限能使用多久？</p>
    <p>
      A：学员缴费后如果不参加考试，则可以一直使用模拟练习的权限，直至考试结果出来。
    </p>
    <br />
  </div>
</template>

<script>
// @ is an alias to /src

export default {
  name: 'Question',
  components: {},
  data() {
    return {}
  },
  methods: {},
}
</script>
<style lang="less" scoped>
.home {
  h3 {
    color: #333;
    padding-bottom: 10px;
  }
  font-weight: normal;
  font-size: 15px;
  color: #666;
  width: 60%;
  min-width: 1200px;
  margin: 50px auto;
  position: relative;
  line-height: 24px;
  .title {
    font-size: 20px;
    padding: 20px 0;
    font-weight: bolder;
    color: #333;
  }
}
</style>
